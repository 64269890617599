<template>
  <section ref="homme" :class="`home detect_deviece_${deviceType} state_${deviceType}`">
    <HeadBar :title="domainConfig['IAmTitle']" :deviceType="deviceType" />
    <section class="home-content">
      <div class="banner">
        <div class="banner-txt">
          Close your eyes and meditate for 5 seconds on your question
          <br>
          Then click the online Book of Answers for clear guidance.
        </div>

        <div class="svgaPage">
          <div id="svga" />
        </div>
        <img v-show="!isPlaying" src="@/assets/book.png" class="book" alt="" @click="getAnswer()">
        <div v-show="!isPlaying" class="mouse-hand" />

        <img src="@/assets/m_banner.png" alt="">
        <div class="answer-btn" @click="getAnswer()">
          Click to view answer
        </div>
      </div>

      <div class="home-intro-row">
        <AdComponent ref="ads-1" class="m-b-40" :ads="adsensConfig.ad1" :showDebug="showDebug" />
        <div class="home-intro-title">
          Discover the Power of "The Book of Answers" Online Version
        </div>
        <div class="home-intro-content">
          "The Book of Answers" is a unique decision-making tool that provides insightful solutions
          for life's dilemmas. With just a click, you receive a new response each time, encouraging
          self-reflection and fresh perspectives on your questions. This randomness adds an engaging
          twist, enhancing your personal growth.
          <br>
          <br>
          Whether you’re facing complex issues or seeking entertainment, "The Book of Answers"
          inspires creativity and exploration. Embrace an open mindset and let it guide your
          decisions. Discover the potential within you today!
        </div>
      </div>

      <div class="home-intro-row">
        <AdComponent ref="ads-2" class="m-b-40" :ads="adsensConfig.ad2" :showDebug="showDebug" />

        <div class="home-intro-title">
          How to Use "The Book of Answers"
        </div>
        <div class="home-intro-content">
          Clarify Your Question: Define your problem clearly; specific questions yield better
          answers.
          <br>
          <br>
          Reflect: Take a moment to think about your question; insightful solutions take time.
          <br>
          <br>
          Use Intuitively: Close your eyes, focus on your question, and randomly open the book for
          an answer.
          <br>
          <br>
          Try Again: If the answer isn’t satisfying, remain calm and try again.
          <br>
          <br>
          Record Insights: Write down the answers for future reference.
          <br>
          <br>
          Follow these steps to enhance your decision-making with "The Book of Answers"!
        </div>
      </div>
      <AdComponent ref="ads-3" class="m-b-40" :ads="adsensConfig.ad3" :showDebug="showDebug" />
      <div class="home-intro-title">
        Q&A
      </div>
      <div class="home-intro-row"
        style="background: #19183b; padding-left: 0; padding-right: 0; padding-top: 0">
        <div class="qa-card">
          <div v-for="(item, index) in questions" :key="index" class="qa-item">
            <button class="qa-question" @click="toggleAnswer(index)">
              <span class="question-text">{{ item.question }}</span>
              <span class="arrow" :class="{ open: item.open }" />
            </button>
            <div v-if="item.open" class="qa-answer">
              {{ item.answer }}
            </div>
          </div>
        </div>
      </div>
      <AdComponent ref="ads-4" class="m-b-40" :ads="adsensConfig.ad4" :showDebug="showDebug" />
      <div class="home-intro-title">
        Something may help
      </div>
      <div class="home-intro-row"
        style="background: #19183b; padding-left: 0; padding-right: 0; padding-top: 0">
        <div class="time-page-card-row">
          <div v-for="(data, i) in card" :key="i" class="time-page-card-item"
            @click="goto(data.routename)">
            <div class="time-page-card-image">
              <img :src="data.img" :alt="data.title">
            </div>
            <div class="time-page-card-content">
              <div class="time-page-card-title">
                {{ data.title }}
              </div>
              <div class="time-page-card-desc">
                {{ data.desc }}
              </div>
            </div>
            <div class="time-page-card-btn">
              Read more
            </div>
          </div>
        </div>
      </div>
    </section>

    <FootBar :deviceType="deviceType" />
  </section>
</template>

<script>
import common from '@/mixin/common.js'
import HeadBar from '@/pages/components/head.vue'
import FootBar from '@/pages/components/footer.vue'
import AdComponent from '@/pages/components/AdComponent.vue'

import { mapGetters } from 'vuex'
import '@/css/home.scss'

export default {
  name: 'HomeMain',
  components: {
    HeadBar,
    FootBar,
    AdComponent,
  },
  mixins: [common],
  data () {
    return {
      isPlaying: false, // 播放状态
      questions: [
        {
          question: 'What is the "Book of Answers Online Edition"?',
          answer: 'The "Book of Answers Online Edition" is a digital tool that helps users quickly obtain answers and guidance for their questions, accessible anytime and anywhere.',
          open: false,
        },
        {
          question: 'Who can benefit from the "Book of Answers Online Edition"?',
          answer: "It's suitable for anyone in need of decision-making, solving doubts, or seeking inspiration, including students, professionals, and life explorers.",
          open: false,
        },
        {
          question: 'What should I prepare when using the "Book of Answers Online Edition"?',
          answer: 'The online version offers similar content to the paper version but may have updates or variations.',
          open: false,
        },
        {
          question: 'Can I use the online edition multiple times?',
          answer: 'Yes, repeating the use of the "Book of Answers Online Edition" can help you gain more insights in different situations and enhance your critical thinking.',
          open: false,
        },
        {
          question: 'Is the online edition suitable for team use?',
          answer: 'Yes, teams can use the online edition together, promoting group discussion and thinking, which enhances collaboration and decision-making efficiency.',
          open: false,
        },
        {
          question: 'Are the answers in the online edition fixed?',
          answer: 'No, the answers are not fixed; they are random results based on your questions and mindset, making each experience unique and flexible for various inquiries.'
          , open: false
        }
      ],
      card: [
        {
          img: require('@/assets/i1.png'),
          title: '"The Book of Answers": Transformative Success Stories',
          desc: "In today's fast-paced, information-saturated world, where social media is omnipresent, many individuals find themselves feeling lost and anxious in their pursuit of success. However, 'The Book of Answers' has served as a unique tool to guide countless individuals towards clarity. It is more than just a guide; it is a transformative tool for a lifestyle shift, enabling people to pursue happiness and achievement with a stronger sense of purpose. ",
          routename: 'blog1',
        },
        {
          img: require('@/assets/i2.png'),
          title: "The Book of Answers: A Beacon of Wisdom and Guidance for Life's Crucial Decisions",
          desc: "The Book of Answers is not just a collection of wisdom but a guiding light in the lives of many readers. Through real-life customer stories, we see how this book has helped individuals make the right decisions at pivotal moments, whether in career choices, emotional decisions, or repairing family relationships. When Li Lei faced a career dilemma, he turned to the Book of Answers and found a simple yet profound message that helped him choose the right path. Similarly, Ms. Zhang, through the book’s advice, repaired her strained relationship with her husband. These success stories show that the Book of Answers is more than just a book; its concise and deep insights have helped countless readers navigate their life's challenges.",
          routename: 'blog2',
        },
        {
          img: require('@/assets/i3.png'),
          title: 'The Accuracy of The Book of Answers: Unveiling the Power of this Mysterious Book',
          desc: "The Book of Answers is a fascinating and mysterious book that has captured the attention of many seeking guidance in life. From personal dilemmas and emotional uncertainties to career choices and everyday decisions, The Book of Answers provides brief responses that help individuals navigate life’s uncertainties. But how accurate are its predictions? Why do so many claim that this book leads them in the right direction? In this article, we will explore the accuracy of The Book of Answers and analyze its unique role in psychology, philosophy, and personal growth.",
          routename: 'blog3',
        },
        {
          img: require('@/assets/i4.png'),
          title: 'How to Interpret The Book of Answers – Unveiling the Wisdom Behind It',
          desc: "The Book of Answers is a unique tool with a mysterious allure, frequently used by those seeking answers to life's questions. Whether it's emotional dilemmas, career decisions, or everyday uncertainties, the book provides brief yet impactful responses that guide readers toward reflection and self-discovery. Its charm doesn't just lie in the simplicity of its answers but in how one interprets and applies these insights. This article will explore how to interpret The Book of Answers, using SEO-optimized keywords like “The Book of Answers meaning,” “how accurate is The Book of Answers,” and “how to use The Book of Answers,” while also demonstrating its role in fostering personal growth and decision-making.",
          routename: 'blog4',
        },
        {
          img: require('@/assets/i5.png'),
          title: 'Industry Insight Report: Trends and Insights of "The Book of Answers"',
          desc: "In today's rapidly evolving knowledge economy, the ability to acquire and interpret information is becoming increasingly important. Particularly in areas such as self-improvement, mental health, and interpersonal relationships, people's craving for spiritual nourishment has made various psychology and philosophy books popular. 'The Book of Answers' as a classic work that explores a variety of life questions, has gained widespread attention for its profound wisdom and practical guidance. This report will analyze current industry trends based on data related to 'The Book of Answers' and showcase our company’s expertise in this field",
          routename: 'blog5',
        },
        {
          img: require('@/assets/i6.png'),
          title: 'The Book of Answers: Common Questions and Unique Responses, and Why People Are Drawn to Divination from a Psychological Perspective',
          desc: "The Book of Answers is a fascinating and interactive divination tool that provides brief yet insightful responses to common life questions. It not only offers philosophical guidance but also draws people seeking clarity and inner reflection. In this article, we will explore The Book of Answers from two angles: first, by listing some common questions and showcasing the book’s unique and detailed responses; second, by analyzing from a psychological perspective why people are drawn to divination books like The Book of Answers and how these tools help them better understand their decisions.",
          routename: 'blog6',
        },

      ],
    }
  },
  computed: {
    ...mapGetters(['showDebug', 'deviceType', 'host', 'adsensConfig']),
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`)
    await this.loadAdSenseScript() // 重新加载广告脚本
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
    this.preloadSVGA(1)
    await this.loadAdSenseScript() // 重新加载广告脚本
    this.watchForIframeInsertion()
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`)
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`)
  },
  methods: {
    //广告相关start
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) {
        // 检查广告是否已经加载
        console.log('Adsense script already loaded.')
        this.$eventrack('adscript_loaded', 'expose')
        this.loadAdWithDelay()
        return // 如果已加载，直接返回
      }
      await this.loadScriptConditionally()
    },
    loadScriptConditionally () {
      console.log(this.adsensConfig)
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链')
        this.$eventrack('no_adscript_config', 'expose')
        return
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`)
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose')
        console.log('脚本已存在，无需重新添加')
        return
      }

      console.log('准备插入脚本')
      const script = document.createElement('script')
      script.src = this.adsensConfig?.scriptUrl
      script.crossOrigin = 'anonymous'
      script.async = true

      const header = document.getElementsByTagName('head')[0]
      header.appendChild(script)

      this.$eventrack('adscript_add_success', 'expose')
      script.onload = this.loadAdWithDelay.bind(this) // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作')
    },
    loadAdWithDelay () {
      setTimeout(() => {
        this.displayAd()

      }, 500)
    },

    //客户端监听
    watchForIframeInsertion () {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node.tagName === 'IFRAME' && node.closest('ins.adsbygoogle')) {
              this.setupIframeTracking(); // 在检测到广告 iframe 插入后，调用 setupIframeTracking
            }
          });
        });
      });

      observer.observe(document.body, { childList: true, subtree: true });
    },
    setupIframeTracking () {
      // let _this = this;
      console.log('ready to track iframe');
      const iframes = document.querySelectorAll('iframe');
      const tracker = new this.IframeTracker(this);
      iframes.forEach((iframe) => {
        tracker.addIframe(iframe, (iframeElement) => {
          console.log('Iframe 点击触发了!', iframeElement);
          const adContainer = iframeElement.closest('ins.adsbygoogle');

          if (adContainer) {
            // 获取父级 ins 元素的边界矩形
            const insRect = adContainer.getBoundingClientRect();

            // 计算父级 ins 元素相对于页面左上角的位置和高度
            const insX = insRect.left;
            const insY = insRect.top;
            const insHeight = insRect.height;

            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;

            let transformData = {
              insX: insX,
              insY: insY,
              insHeight: insHeight,
              windowWidth: windowWidth,
              windowHeight: windowHeight,
            }
            this.$adClick(JSON.stringify(transformData))
            tracker.trackEvent('ad_iframe_click', 'click', {
              'data-ad-slot': adContainer.dataset.adSlot,
            });
          }
        });
      });
    },
    IframeTracker: class {
      constructor(vues, resolution = 200) {
        this.vues = vues; // 保存 Vue 实例
        this.resolution = resolution;
        this.iframes = [];
        this.interval = null;
        this.activeIframe = null; // 存储最近被点击的iframe
        this.setupVisibilityTracking();
      }

      addIframe (element, callback) {
        const adContainer = element.closest('ins.adsbygoogle');
        const iframe = {
          element: element,
          callback: callback,
          hasTracked: false,
          adSlot: adContainer ? adContainer.dataset.adSlot : null,
        };
        this.iframes.push(iframe);

        if (!this.interval) {
          this.interval = setInterval(() => this.checkClick(), this.resolution);
        }
      }

      checkClick () {
        const activeElement = document.activeElement;
        if (activeElement) {
          this.iframes.forEach((iframe) => {
            if (activeElement === iframe.element && !iframe.hasTracked) {
              iframe.callback(iframe.element);
              iframe.hasTracked = true;
              this.activeIframe = iframe; // 更新最近被点击的iframe
            }
          });
        }
      }

      setupVisibilityTracking () {
        const hiddenProperty =
          'hidden' in document ? 'hidden' : 'webkitHidden' in document ? 'webkitHidden' : 'mozHidden' in document ? 'mozHidden' : null;
        const visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange');

        const onVisibilityChange = () => {
          if (!document[hiddenProperty]) {
            console.log('页面激活状态,返回页面?');
            this.activeIframe = null; // 重置最近被点击的iframe记录
          } else {
            console.log('页面非激活状态');

            if (this.activeIframe) {
              // 上报逻辑
              this.trackEvent('ad_iframe_click_leave', 'click', {
                'data-ad-slot': this.activeIframe.adSlot,
              });
            }
          }
        };

        this.iframeVisibilityTracker = onVisibilityChange;
        document.addEventListener(visibilityChangeEvent, this.iframeVisibilityTracker);
      }

      trackEvent (eventName, eventValue, dataAttributes) {
        // 事件追踪逻辑
        console.log(`${eventName} with value ${eventValue} on`, dataAttributes);

        this.vues.$eventrack(eventName, eventValue, {
          ...dataAttributes,
        });
      }
    },

    async displayAd () {
      await this.$nextTick()

      // 获取所有 ads 元素的 refs，过滤掉非广告的 ref
      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ads-')) // 只选择以 'ads-' 开头的 refs
        .flatMap(([, ref]) => ref) // 展开并获取所有元素

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log('Adsense script not loaded yet, delaying ad display.')
        setTimeout(this.displayAd, 500) // 延迟再次尝试
        return
      }

      console.log(adsElements) // 检查是否包含 <ins> 标签
      adsElements.forEach((ad) => {
        console.log(ad) // 输出每个广告元素
        console.log('ready to push')
          ; (window.adsbygoogle = window.adsbygoogle || []).push({})
      })
    },
    //广告相关end

    async playSvga () {
      await this.loadAndPlaySVGA()
    },
    async loadAndPlaySVGA () {
      try {
        const videoItem = await this.preloadSVGA()
        const player = this.createSVGAPlayer()
        player.loops = 1
        player.setVideoItem(videoItem)
        player.startAnimation()
        this.isPlaying = true // 设置为播放状态
        console.log('start')
        // 监听播放完成事件
        player.onFinished(() => {
          this.isPlaying = false // 播放结束时设置状态
          console.log('done')
          this.gotoURL('answer')
        })
      } catch (error) {
        console.error('SVGA加载失败:', error)
      }
    },
    gotoURL (path) {
      const currentParams = this.$router.currentRoute.params; // 当前路由的 params
      const currentQuery = this.$route.query; // 当前路由的 query

      const channel = currentParams.channel ? `/${currentParams.channel}` : ''; // 拼接 channel path
      const queryString = new URLSearchParams(currentQuery).toString(); // 转换 query 对象为字符串
      const fullQueryString = queryString ? `?${queryString}` : ''; // 如果有查询参数，拼接为 ?xxx=yyy 格式

      window.location.href = `${channel}/${path}${fullQueryString}`;

    },
    preloadSVGA () {
      // const url = "https://ptc-dressup-sg2.obs.ap-southeast-3.myhuaweicloud.com/rest/test/20231214/send.svga"
      const url = '/animate.svga'
      const cacheKey = 'createCache'
      const SVGA = require('svgaplayerweb')

      return new Promise((resolve, reject) => {
        if (this[cacheKey]) {
          resolve(this[cacheKey])
        } else {
          const parser = new SVGA.Parser()
          parser.load(
            url,
            (videoItem) => {
              this[cacheKey] = videoItem
              resolve(videoItem)
            },
            reject,
          )
        }
      })
    },
    createSVGAPlayer () {
      const SVGA = require('svgaplayerweb')

      const elementId = '#svga'
      return new SVGA.Player(elementId)
    },
    toggleAnswer (index) {
      this.questions[index].open = !this.questions[index].open
    },
    getAnswer () {
      this.playSvga(1)
    },
    goto (name) {
      this.$router.push({ name: name })
    },
  },
}
</script>
